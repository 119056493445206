import { Card } from "baseui/card";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Tag, KIND } from "baseui/tag";
import { Block } from "baseui/block";
import { ChevronRight } from "baseui/icon";
import { Job } from '@/network/definitions/job';

interface JobCardProps {
    job: Job;
    actionComponent?: React.ReactNode;
    onCardClick?: () => void;
    pointerCursor?: boolean;
}

export const JobCard = ({
    job,
    actionComponent = null,
    onCardClick,
    pointerCursor = false
}: JobCardProps) => {
    const navigate = useNavigate();
    const {
        id,
        role: title,
        active,
    } = job;
    return (
        <Card
            key={id}
            overrides={{
                Root: {
                    style: ({ $theme }) => ({
                        backgroundColor: $theme.colors.backgroundSecondary, // Customize the color
                        width: "100%",
                        cursor: pointerCursor ? "pointer" : "default",
                        marginBottom: '30px'
                    }),
                    props: {
                        onClick: () => {
                           if (!onCardClick) {
                            navigate(`/job/${id}`)
                           } else {
                            onCardClick();
                           }
                        },
                    }
                }
            }}
        >
            <FlexGrid
                flexGridColumnCount={1}
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Block display="flex" flexDirection="column">
                    <Block display="flex" alignItems="center">
                        <Block as="h3" marginLeft="scale300" marginBottom="scale100">
                            {id} - {title}
                        </Block>
                    </Block>
                    <Block marginTop="scale400">
                        <FlexGrid
                            flexGridColumnCount={5}
                            gridColumnGap="scale400"
                            gridRowGap="scale200"
                        >
                            <FlexGridItem>
                                <Tag closeable={false} kind={active ? KIND.positive : KIND.neutral}>
                                    {active ? "Active" : "Inactive"}
                                </Tag>
                            </FlexGridItem>
                        </FlexGrid>
                    </Block>
                </Block>
                {actionComponent ? actionComponent : <ChevronRight />}
            </FlexGrid>
        </Card>
    )
}