import React from 'react';
import { useParams } from "react-router-dom";
import { Card } from "baseui/card";
import { Tabs, Tab } from 'baseui/tabs';
import { Block } from 'baseui/block';
import { Button } from "baseui/button";
import { StyledLink } from 'baseui/link';
import { UserPlusIcon } from "@/assets/icons/user-plus";
import { useNavigate } from "react-router-dom";
import useJobStore from "../../store/jobs";
import { JobCard } from "../../components/jobs/job";
import { NavigateBack } from "../../components/navigation/navigate-back";
import CandidateTable, {filterConstant} from '../../components/job-candidates-list/candidate-table';

interface AddCandidateButtonProps {
    jobId: number;
}

const AddCandidateButton = ({
    jobId
}: AddCandidateButtonProps) => {
    const navigate = useNavigate();
    return (
        <Button
            onClick={() => navigate(`/job/${jobId}/add-candidate`)}
            overrides={{
                BaseButton: {
                    style: ({ $theme }) => ({
                        paddingLeft: $theme.sizing.scale600,
                        paddingRight: $theme.sizing.scale600,
                        borderRadius: $theme.borders.radius400, // Rounded corners
                    }),
                },
            }}
            startEnhancer={() => <UserPlusIcon />} // Icon on the left
        >
            Add a candidate
        </Button>
    );
};

const JobDetailsTabs = ({job}) => {
  const [activeKey, setActiveKey] = React.useState('1');

    return (
        <Card
            overrides={{
                Root: {
                    style: ({ $theme }) => ({
                        backgroundColor: $theme.colors.backgroundSecondary, // Customize the color
                        padding: $theme.sizing.scale100,
                        minWidth: "100%",
                        width: "100%",
                    }),
                },
            }}
        >
            <Tabs
                activeKey={activeKey}
                onChange={({ activeKey }) => {
                    // @ts-ignore
                    setActiveKey(activeKey);
                }}
                overrides={{
                    TabBar: {
                      style: ({ $theme }) => ({
                        borderBottom: `2px solid ${$theme.colors.backgroundPrimary}`,
                        backgroundColor: $theme.colors.backgroundSecondary
                      })
                    }
                  }}
            >
                <Tab title="Job details">
                    <Block
                        padding="scale100"
                    >
                        {job.agenciesJobDescriptionLink ? (
                            <StyledLink
                                href={job.agenciesJobDescriptionLink}
                                target="_blank"
                                $style={({ $theme }) => ({
                                    fontSize: $theme.typography.LabelLarge.fontSize,
                                    color: $theme.colors.accent,
                                    fontWeight: 'bold',
                                })}
                            >
                                Job Description Link
                            </StyledLink>
                        ) : 'Job description link is not provided'}
                    </Block>
                </Tab>
                <Tab title="All candidates">
                    <CandidateTable filter={filterConstant.ALL}/>
                </Tab>
                <Tab title="Selected candidates">
                    <CandidateTable filter={filterConstant.SELECTED}/>
                </Tab>
            </Tabs>
        </Card>
    );
};





const JobDetails = () => {
    const { id = null } = useParams();
    if (!id) return null;
    const getJobById = useJobStore(state => state.getJobById);
    const job = getJobById(Number(id));
    if (!job) return;
    return (
        <>
            <NavigateBack
                route={`/jobs`}
                text="Back to job board"
            />
            <JobCard
                job={job}
                actionComponent={<AddCandidateButton jobId={job.id}/>}
                onCardClick={() => { }}
            />
            <JobDetailsTabs job={job}/>
        </>
    )
}

export default JobDetails;