import useAuthStore from "@/store/auth";

type FetcherParams = {
    queryKey: [string, { url: string; params?: Record<string, string | number | boolean> }];
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    body?: Record<string, unknown> | FormData | null;
  };
  
  
export const fetcher = async <T>({ queryKey, method = 'GET', body = null }: FetcherParams): Promise<T> => {
    const [_key, { url, params }] = queryKey;
    const baseURL = import.meta.env.VITE_API_BASE_URL as string || '';
  
    const queryString = params ? `?${new URLSearchParams(params as Record<string, string>).toString()}` : '';
    const fullUrl = `${baseURL}${url}${queryString}`;
    const token = useAuthStore.getState().token    
    const options: RequestInit = {
        method,
        headers: {
            'Authorization': `Bearer ${token}`,
            ...(body instanceof FormData ? {} : { 'Content-Type': 'application/json' }), // Do not set Content-Type for FormData
        },
        body: body instanceof FormData ? body : body ? JSON.stringify(body) : null, // Use FormData directly, or JSON stringify the body if not null
    };
    const response = await fetch(fullUrl, options)
    if (response.status === 401 && !url.includes('login')) {
      useAuthStore.getState().logout()
      return Promise.reject(response);
    }
    return response.json() as Promise<T>;
  };
  