import { useGet } from "./apiHooks";

export const useBulkUploadCounts = () => {
    const { data: countData = {} } = useGet('agency-dev-applications/bulk-imports/counts');
    const {
        data: {
            all = 0, completed = 0, error = 0, processing = 0, pending = 0
        } = {},
    } = countData as any;
    return { all, completed, processing, pending, error };
}