import { FlexGrid } from 'baseui/flex-grid';
import { useBulkUploadCounts } from '@/hooks/useBulkUploadCountsHooks';
import { KIND, Tag } from 'baseui/tag';

const InlineBulkUploadStatus = () => {
  const { all, completed, processing, pending, error } = useBulkUploadCounts();

  return (
    <FlexGrid
      flexGridColumnCount={5}
      justifyContent="space-between"
      flexGridColumnGap="scale500"
      flexGridRowGap="scale400"
      marginTop="20px"
    >
      <Tag closeable={false} kind={KIND.accent}>
        Total: {all}
      </Tag>
      <Tag closeable={false} kind={KIND.positive}>
        Completed: {completed}
      </Tag>
      <Tag closeable={false} kind={KIND.neutral}>
        Processing: {processing}
      </Tag>
      <Tag closeable={false} kind={KIND.orange}>
        Pending: {pending}
      </Tag>
      <Tag closeable={false} kind={KIND.negative}>
        Errors: {error}
      </Tag>
    </FlexGrid>
  );
};

export default InlineBulkUploadStatus;