import moment from 'moment';

const formatDate = (dateString) => {
    if (!dateString || !moment(dateString).isValid()) {
        return '';
    }

    const today = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    const date = moment(dateString);

    const diffInDays = today.diff(date, 'days');

    if (diffInDays == 0) {
        return 'Today'
    }
    if (diffInDays === 1) {
        return `Yesterday (${date.format('MM/DD/YYYY')})`
    }
    if (diffInDays === -1) {
        return `Tomorrow (${date.format('MM/DD/YYYY')})`
    }
    return `${date.from(today)} (${date.format('MM/DD/YYYY')})`;
};

export default formatDate;