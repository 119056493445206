import { HeadingLarge, HeadingSmall } from "baseui/typography";
import { Jobs } from "@/components/jobs";
import { Button } from "baseui/button";
import InlineBulkUploadStatus from "@/components/bulk-upload/inline-bulk-upload-status";;
import { FlexGrid } from "baseui/flex-grid";
import { Card } from "baseui/card";


const Home = () => {
    const navigate = useNavigate();
    return (
        <>  
            <style>
            {`
                @keyframes glow {
                    0% {
                        box-shadow: 0px 0px 6px rgba(0, 123, 255, 0.15);
                    }
                    50% {
                        box-shadow: 0px 0px 12px rgba(0, 123, 255, 0.3);
                    }
                    100% {
                        box-shadow: 0px 0px 6px rgba(0, 123, 255, 0.15);
                    }
                }
            `}
            </style>
            <Card
                overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        backgroundColor: $theme.colors.backgroundSecondary, // Customize the color
                        padding: $theme.sizing.scale100,
                        minWidth: "100%",
                        width: "100%",
                        animation: 'glow 2s ease-in-out infinite', 
                        cursor: 'pointer'
                      }),
                    },
                    
                }}
            >
                <FlexGrid justifyContent="space-between" alignItems="center">
                    <FlexGrid flexDirection="column">
                        <HeadingSmall style={{ marginBottom: '5px', marginTop: 0 }}>Bulk Upload Status</HeadingSmall>
                        <InlineBulkUploadStatus />
                    </FlexGrid>
                    <FlexGrid gridGap="scale800">
                        <Button
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/list-bulk-candidates')
                            }}
                        >
                            View Bulk Candidates
                        </Button>
                        <Button
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/add-bulk-candidates')
                            }}
                        >
                            Upload Bulk Candidates
                        </Button>
                    </FlexGrid>
                </FlexGrid>
            </Card>
            <HeadingLarge>Jobs</HeadingLarge>
            <Jobs />
        </>
    )
}

export default Home;